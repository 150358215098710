import React from "react"

function Footer() {
  return (
    <footer className="footer container">
      <div className="footer__col">
        <h3 className="footer__hdg">los angeles</h3>
      </div>
      <div className="footer__col">
        <a className="footer__link" href="mailto:hello@atomicdesigngroup.com">
          hello
        </a>
      </div>
    </footer>
  )
}

export default Footer
